import React, { ReactNode } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _get from 'lodash.get';
import moment from 'moment';
import uuid from 'uuid';

import { Button } from 'antd';
import { Formik, FormikState, Field } from 'formik';
import { Input, handleSubmit } from '@while-and-for/forms';
import { Loading, ErrorComponent } from '@while-and-for/components';

import { GET_EVENT, GET_EVENTS } from 'graphql/queries';

import {
  DateRange,
  Form,
  SelectBrands,
  SelectCountries
} from 'forms/components';

import FormSchema from './FormSchema';
import { UPSERT_EVENT, onError, onSuccess } from './requests';

type BrandType = {
  id?: string;
  brand_id: string;
  event_id: string;
  __typename?: string;
};

type Values = {
  id: string;
  name: string;
  country_id: string;
  event_brands: BrandType[];
  brands: string[];
  link?: string;
  date_range: [string, string];
  updated_at?: moment.Moment;
};

type Props = RouteComponentProps & {
  id?: string;
};

const EditProfile: React.FC<Props> = ({ history, id }) => {
  const { data, loading, error } = useQuery(GET_EVENT, {
    variables: {
      id
    },
    skip: !id,
    fetchPolicy: 'network-only'
  });

  const [upsertEvent] = useMutation(UPSERT_EVENT, {
    refetchQueries: [{ query: GET_EVENTS }]
  });

  if (loading) return <Loading />;
  if (error) return <ErrorComponent error={error} />;

  const { name, country_id, start_date, end_date, link, event_brands } = _get(
    data,
    'event_by_pk',
    {}
  );
  const brands = event_brands.map(({ brand_id }: BrandType) => brand_id);

  return (
    <Formik
      validationSchema={FormSchema}
      onSubmit={handleSubmit({
        onError,
        onSuccess: onSuccess(history),
        submit: async ({
          date_range,
          brands,
          event_brands,
          ...values
        }: Values): Promise<void> => {
          const deleted: string[] = [];
          const res = brands.map((value: string) => {
            const event_brand = event_brands.find(
              ({ brand_id }) => value === brand_id
            );
            event_brand && delete event_brand.__typename;
            return (
              event_brand ??
              ({
                brand_id: value,
                event_id: id
              } as BrandType)
            );
          });
          event_brands.forEach(
            ({ brand_id, id }) =>
              !brands.includes(brand_id) && deleted.push(id as string)
          );
          const [start, end] = date_range;
          await upsertEvent({
            variables: {
              ...values,
              event_brands: res,
              start_date: start,
              end_date: end,
              deleted
            }
          });
        }
      })}
      initialValues={{
        id: id || uuid.v4(),
        name: name || undefined,
        country_id: country_id || undefined,
        brands: brands || [],
        event_brands,
        link: link || undefined,
        date_range: [start_date, end_date],
        updated_at: moment()
      }}
    >
      {({ isSubmitting }: FormikState<Values>): ReactNode => (
        <Form>
          <Field
            required
            label="Activity name"
            name="name"
            component={Input}
            placeholder="Enter activity name"
          />
          <Field
            required
            multiple
            label="Brand"
            name="brands"
            component={SelectBrands}
            placeholder="Select Brand"
          />
          <Field
            required
            label="Country"
            name="country_id"
            component={SelectCountries}
            placeholder="Select Country"
          />
          <Field
            label="Link"
            name="link"
            component={Input}
            placeholder="Enter an external href"
          />
          <Field
            required
            label="Date"
            name="date_range"
            placeholder="Select date range"
            component={DateRange}
          />
          <Button
            block
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(EditProfile);
