import React, { ReactNode } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import _get from 'lodash.get';
import uuid from 'uuid';
import { Button } from 'antd';
import { Formik, FormikState, Field } from 'formik';
import { handleSubmit, Input } from '@while-and-for/forms';
import { Form, SelectCountries } from 'forms/components';
import { Loading, ErrorComponent } from '@while-and-for/components';
import { ADMIN_GET_BRANDS } from 'graphql/queries';
import { GET_BRAND_BY_ID, ADD_BRAND, onError, onSuccess } from './requests';

import FormSchema from './FormSchema';
import { CountryType, Values, Props, initialValues } from './types';

const BrandForm: React.FC<Props> = ({ history, id }) => {
  const { data, loading, error } = useQuery(GET_BRAND_BY_ID, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only'
  });
  const [updateBrand] = useMutation(ADD_BRAND, {
    refetchQueries: [{ query: ADMIN_GET_BRANDS }]
  });
  if (loading) return <Loading />;
  if (error) return <ErrorComponent error={error} />;

  const { __typename, ...brand } = _get(data, 'brand_by_pk', {});
  brand.country = brand.brand_countries.map(
    ({ country_id }: CountryType) => country_id
  );

  return (
    <Formik
      validationSchema={FormSchema}
      onSubmit={handleSubmit({
        onError,
        onSuccess: onSuccess(history),
        submit: async ({ country, brand_countries, ...values }: Values) => {
          const res: CountryType[] = [];
          const deleted: string[] = [];
          country.forEach((value: string) => {
            const brand_country = brand_countries.find(
              ({ country_id }) => value === country_id
            );
            brand_country && delete brand_country.__typename;
            return res.push(
              brand_country ??
                ({
                  country_id: value,
                  brand_id: id
                } as CountryType)
            );
          }, []);
          brand_countries.forEach(
            ({ country_id, id }) =>
              !country.includes(country_id) && deleted.push(id as string)
          );
          await updateBrand({
            variables: {
              ...values,
              brand_countries: res,
              deleted
            }
          });
        }
      })}
      initialValues={{
        ...(id ? brand : initialValues),
        id: id || uuid.v4()
      }}
    >
      {({ isSubmitting }: FormikState<Values>): ReactNode => (
        <Form>
          <Field
            required
            label="Name"
            name="name"
            component={Input}
            placeholder="Enter Brand Name"
          />
          <Field
            multiple
            label="Country"
            name="country"
            component={SelectCountries}
            placeholder="Select location"
          />
          <Field
            required
            label="Logo"
            name="logo"
            component={Input}
            placeholder="Select Logo"
          />
          <Button
            block
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(BrandForm);
