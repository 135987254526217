import Auth from '@aws-amplify/auth';
import { message } from 'antd';

type Values = {
  password?: string;
};
export const submit = (user: Record<string, unknown>) => async ({
  password
}: Values): Promise<void> => {
  if (!password) throw Error('Missing password');
  await Auth.completeNewPassword(user, password, {});
};

export const onError = (e: Error): void => {
  message.error(e.message);
};
