import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('name is required'),
  country_id: Yup.string().required('country is required'),
  brands: Yup.array(Yup.string())
    .min(1, 'at least one brand is required')
    .required('brand is required'),
  link: Yup.string().nullable(),
  date_range: Yup.array().required('date range is required')
});
