import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _get from 'lodash.get';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { List } from 'antd';
import { CardData } from 'components';

import Avatar from './Avatar';

import { GET_MARKETS_BY_IDS } from './requests';

const { Item } = List;

type Market = {
  id: string;
  colour: string;
  name: string;
};

type Search = {
  m?: string;
};

type Props = {
  marketIds?: string[];
  visible: boolean;
} & RouteComponentProps;

const Markets: React.FC<Props> = ({
  history,
  location,
  marketIds,
  visible
}) => {
  const { m, ...search } = queryString.parse(location.search) as Search;
  const { data, loading } = useQuery(GET_MARKETS_BY_IDS, {
    variables: { marketIds },
    skip: !marketIds || marketIds.length === 0
  });

  const markets = _get(data, 'market');

  if (!markets || markets.length === 0) return null;

  const toggleMarket = (id: string): void => {
    const active = id === m;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        ...search,
        ...{ m: active ? undefined : id }
      })
    });
  };

  return (
    <CardData
      padding={false}
      loading={loading}
      visible={visible}
      title="Markets"
      left="20px"
      bottom="20px"
    >
      <List
        size="small"
        dataSource={markets}
        renderItem={({ colour, id, name }: Market): ReactNode => (
          <Item
            key={id}
            onClick={(): void => toggleMarket(id)}
            style={{
              backgroundColor: id === m ? 'rgba(0, 0, 0, 0.2)' : undefined
            }}
          >
            <Avatar colour={colour} size="small" /> {name}
          </Item>
        )}
      />
    </CardData>
  );
};

export default withRouter(Markets);
