// Core
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Utils
import { date } from '@while-and-for/forms';
import useSub from 'utilities/useSub';

// Queries
import { DELETE_MAP } from 'graphql/mutations';
import { GET_MAPS } from 'graphql/queries';

// Components
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { message, Popconfirm, Button, Card as DefaultCard } from 'antd';

import { ButtonGroup } from './style';

const { Meta } = DefaultCard;

type Props = RouteComponentProps & {
  id: string;
  title?: string;
  updated_at: string;
  long: number;
  lat: number;
};

const Card: React.FC<Props> = ({
  id,
  title,
  updated_at,
  long,
  lat,
  history
}) => {
  const { sub } = useSub();
  const url = `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/${long},${lat},5.56/600x300?access_token=pk.eyJ1IjoicmVteXByb2plY3QiLCJhIjoiY2s2MXJ1aDM2MDZ0NjNkcW9pZXVqd2JhNSJ9.S10Px-XSRh-ldCAd1Uh8fQ`;

  const [deleteMetrics] = useMutation(DELETE_MAP);

  const deleteMap = async (): Promise<void> => {
    try {
      await deleteMetrics({
        variables: { id },
        refetchQueries: [{ query: GET_MAPS, variables: { user_id: sub } }]
      });
      message.success('Map deleted!');
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <DefaultCard
      key={id}
      hoverable
      style={{ borderRadius: '2px', overflow: 'hidden' }}
      cover={
        <img
          onClick={(): void => history.push(`/app/maps/${id}`)}
          alt="example"
          src={url}
        />
      }
    >
      <div onClick={(): void => history.push(`/app/maps/${id}`)}>
        <Meta title={title || '(untitled)'} description={date(updated_at)} />
      </div>
      <ButtonGroup>
        <Button
          onClick={(): void => history.push(`/app/maps/${id}`)}
          icon={<EditOutlined />}
          size="large"
        />
        <Popconfirm
          title="Do you want to delete this map?"
          okText="Yes"
          cancelText="No"
          onConfirm={deleteMap}
        >
          <Button
            type="default"
            icon={<DeleteOutlined style={{ color: '#f2575c' }} />}
            size="large"
          />
        </Popconfirm>
      </ButtonGroup>
    </DefaultCard>
  );
};

export default withRouter(Card);
