import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';

import gql from 'graphql-tag';

export const UPSERT_EVENT = gql`
  mutation UpsertEvent(
    $id: uuid!
    $name: String!
    $country_id: uuid!
    $event_brands: [brand_event_insert_input!]!
    $link: String
    $start_date: timestamptz!
    $end_date: timestamptz!
    $updated_at: timestamptz!
    $deleted: [uuid!]!
  ) {
    insert_event(
      objects: {
        id: $id
        name: $name
        country_id: $country_id
        link: $link
        start_date: $start_date
        end_date: $end_date
        updated_at: $updated_at
      }
      on_conflict: {
        constraint: event_pkey
        update_columns: [
          name
          country_id
          start_date
          link
          end_date
          updated_at
        ]
      }
    ) {
      returning {
        id
        name
        country_id
        link
        start_date
        end_date
      }
    }
    insert_brand_event(
      objects: $event_brands
      on_conflict: { constraint: brand_event_pkey, update_columns: [] }
    ) {
      affected_rows
    }
    delete_brand_event(where: { id: { _in: $deleted } }) {
      affected_rows
    }
  }
`;

export const onError = (e: Error): void => {
  message.error(e.message);
};

export const onSuccess = (
  history: RouteComponentProps['history']
) => (): void => {
  message.success('Activity saved');
  history.goBack();
};
