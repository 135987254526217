import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';

export const GET_BRAND_BY_ID = gql`
  query BrandById($id: uuid!) {
    brand_by_pk(id: $id) {
      id
      name
      logo
      brand_countries(order_by: { country: { name: asc } }) {
        id
        brand_id
        country_id
      }
    }
  }
`;

export const ADD_BRAND = gql`
  mutation Upsertbrand(
    $id: uuid
    $name: String
    $logo: String
    $brand_countries: [brand_country_insert_input!]!
    $deleted: [uuid!]!
  ) {
    insert_brand(
      objects: { id: $id, name: $name, logo: $logo }
      on_conflict: { constraint: brand_pkey, update_columns: [name] }
    ) {
      affected_rows
    }
    insert_brand_country(
      objects: $brand_countries
      on_conflict: { constraint: brandCountry_pkey, update_columns: [] }
    ) {
      affected_rows
    }
    delete_brand_country(where: { id: { _in: $deleted } }) {
      affected_rows
    }
  }
`;
export const onError = (e: Error): void => {
  message.error(e.message);
};

export const onSuccess = (
  history: RouteComponentProps['history']
) => (): void => {
  message.success('Brand saved');
  history.goBack();
};
