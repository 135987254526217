import { RouteComponentProps } from 'react-router-dom';
import uuid from 'uuid';

export type Values = {
  country: string[];
  name?: string;
  id?: string;
  brand_countries: CountryType[];
  logo?: string;
};

export type CountryType = {
  id?: string;
  country_id: string;
  brand_id: string;
  __typename?: string;
};

export const initialValues = {
  country: [],
  name: undefined,
  id: uuid.v4(),
  logo: undefined,
  brand_countries: []
} as Values;

export type Props = RouteComponentProps & {
  id?: string;
};
