import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import _get from 'lodash.get';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CardData } from 'components';
import { Button, Table } from 'antd';

import { createMetricsFilter, createPopulationFilter } from 'utilities/filters';
import { MetricsDisplayForm } from 'forms';

import { columns, dataMap } from './table';

import { GET_CORE_METRICS_BY_CONFIG } from './requests';

type Config = {
  brands?: [string];
  date_range?: [string, string];
  countries?: string[];
  markets?: string[];
};

type Params = {
  id: string;
};

type Search = {
  b?: string;
  c?: string;
  d?: boolean;
  m?: string;
};

type Props = {
  config: Config;
  displayConfig: {
    cop: boolean;
    cns_le: boolean;
    a_and_p: boolean;
    cns_budget: boolean;
    head_count: boolean;
    population: boolean;
    a_and_p_cns: boolean;
    gross_margin: boolean;
    percent_cognac: boolean;
    sales_volume_le: boolean;
    net_contribution: boolean;
    gross_margin_ratio: boolean;
    sales_volume_budget: boolean;
  };
  _public: boolean;
  visible: boolean;
} & RouteComponentProps<Params>;

const Coremetrics: React.FC<Props> = ({
  config,
  displayConfig,
  history,
  location,
  match,
  _public,
  visible
}) => {
  const { pathname } = location;
  const search = queryString.parse(location.search) as Search;
  const { id } = match.params;

  const { b, c, d, m } = search;
  const args = {
    ...config,
    ...(b && {
      brands: [b]
    }),
    ...(c && {
      countries: [c],
      markets: []
    }),
    ...(m && {
      countries: [],
      markets: [m]
    })
  };

  const filter = createMetricsFilter(args);
  const countryFilter = createPopulationFilter(args);

  const { data, loading } = useQuery(GET_CORE_METRICS_BY_CONFIG, {
    variables: {
      config: filter,
      countryConfig: countryFilter
    },
    skip: !filter
  });

  const aggregate = _get(data, 'metrics_aggregate.aggregate');

  if (!aggregate) return null;

  const { avg, sum } = aggregate;
  const metrics = {
    ...avg,
    ...sum,
    population: _get(data, 'country_aggregate.aggregate.sum.population', null)
  };

  const dataSource = dataMap
    .filter(({ key }) => !!displayConfig[key])
    .map((i) => {
      const { format, key } = i;
      const result = metrics[key];
      return {
        ...i,
        result: result ? (format ? format(result) : result) : '–'
      };
    });

  return (
    <CardData
      loading={loading}
      visible={visible}
      padding={false}
      title="Core Metrics"
      bottom="20px"
      right="20px"
      width="250px"
      extra={
        !d &&
        !_public && (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={(): void => {
              delete search.d;
              history.push({
                pathname,
                search: queryString.stringify({
                  ...search,
                  ...(!d && { d: true })
                })
              });
            }}
          >
            Edit
          </Button>
        )
      }
    >
      {d ? (
        <MetricsDisplayForm
          id={id}
          close={(): void => {
            delete search.d;
            history.push({
              pathname,
              search: queryString.stringify({
                ...search
              })
            });
          }}
        />
      ) : (
        <Table
          showHeader={false}
          dataSource={dataSource ?? []}
          columns={columns}
          pagination={false}
          size="small"
          rowKey="key"
        />
      )}
    </CardData>
  );
};

export default withRouter(Coremetrics);
