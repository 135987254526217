import gql from 'graphql-tag';

export const GET_BRAND_EVENTS_BY_CONFIG = gql`
  query GetEventsByConfig($config: brand_bool_exp) {
    brand(where: $config) {
      id
      name
      brand_events {
        event {
          id
          name
          link
        }
      }
    }
  }
`;
