import gql from 'graphql-tag';

export const GET_BRANDS_BY_IDS = gql`
  query GetBrandsByIds($config: brand_bool_exp) {
    brand(where: $config) {
      id
      logo
      name
    }
  }
`;
