import React, { ReactNode } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

import Actions from '../Actions';
import { Input, Button } from 'antd';

type Users = {
  shortcode: string;
  active: boolean;
  admin: boolean;
  name: string;
  first_name: string;
  last_name: string;
  registration_complete: boolean;
};

const columns = [
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: function RenderTenant(
      name: string,
      { first_name, last_name }: Users
    ): ReactNode {
      return `${first_name} ${last_name}`;
    },
    filterDropdown: function Filter({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: {
      selectedKeys: string[];
      setSelectedKeys: (a?: string[]) => void;
      confirm: () => void;
      clearFilters: () => void;
    }): ReactNode {
      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search User`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
            onClick={(): void => confirm()}
          >
            Search
          </Button>
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={(): void => clearFilters()}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: function RenderIcon(): ReactNode {
      return <SearchOutlined />;
    },
    onFilter: (value: string | number | boolean, record: Users): boolean => {
      if (typeof value !== 'string') return false;
      return (record['first_name'] + record['last_name'])
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    }
  },
  {
    title: 'Role',
    dataIndex: 'admin',
    filters: [
      {
        text: 'Admin',
        value: 'true'
      },
      {
        text: 'User',
        value: 'false'
      }
    ],
    filterMultiple: false,
    onFilter: (value: string | number | boolean, record: Users): boolean =>
      value === 'true' ? record['admin'] === true : record['admin'] === false,
    render: function RenderActions(admin: boolean): ReactNode {
      return admin ? (
        <Tag color="red">Admin</Tag>
      ) : (
        <Tag color="blue">User</Tag>
      );
    }
  },
  {
    title: 'Status',
    dataIndex: 'registration_complete',

    filters: [
      {
        text: 'Complete',
        value: 'true'
      },
      {
        text: 'Pending',
        value: 'false'
      }
    ],
    filterMultiple: false,
    onFilter: (value: string | number | boolean, record: Users): boolean =>
      value === 'true'
        ? record['registration_complete'] === true
        : record['registration_complete'] === false,
    render: function RenderActions(registration_complete: boolean): ReactNode {
      return registration_complete ? (
        <Tag color="green">Complete</Tag>
      ) : (
        <Tag color="gold">Pending</Tag>
      );
    }
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: function RenderActions(id: string, { active }: Users): ReactNode {
      return <Actions id={id} active={active} />;
    }
  }
];

export default columns;
