import gql from 'graphql-tag';

export const UPDATE_MAP_DISPLAY = gql`
  mutation UpdateMapDisplay($id: uuid!, $display_config: jsonb!) {
    update_map(
      where: { id: { _eq: $id } }
      _set: { display_config: $display_config }
    ) {
      returning {
        id
        config
        display_config
        lat
        long
        zoom
        updated_at
      }
    }
  }
`;
