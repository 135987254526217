import React, { ReactNode } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import _get from 'lodash.get';
import uuid from 'uuid';
import { Button } from 'antd';
import { Formik, FormikState, Field } from 'formik';
import { handleSubmit, Input } from '@while-and-for/forms';
import { Form, SelectCountries, Switch, ColorPicker } from 'forms/components';
import { Loading, ErrorComponent } from '@while-and-for/components';
import { ADMIN_GET_MARKETS } from 'graphql/queries';
import { GET_MARKET_BY_ID, ADD_MARKET, onError, onSuccess } from './requests';

import FormSchema from './FormSchema';
import { CountryMarketType, Values, Props, initialValues } from './types';

const MarketForm: React.FC<Props> = ({ history, id }) => {
  const { data, loading, error } = useQuery(GET_MARKET_BY_ID, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only'
  });
  const [updateMarket] = useMutation(ADD_MARKET, {
    refetchQueries: [{ query: ADMIN_GET_MARKETS }]
  });
  if (loading) return <Loading />;
  if (error) return <ErrorComponent error={error} />;

  const { __typename, ...market } = _get(data, 'market_by_pk', {});

  market.country = market.market_countries.map(
    ({ country_id }: CountryMarketType) => country_id
  );

  return (
    <Formik
      validationSchema={FormSchema}
      onSubmit={handleSubmit({
        onError,
        onSuccess: onSuccess(history),
        submit: async ({ country, market_countries, ...values }: Values) => {
          const deleted: string[] = [];
          const res: CountryMarketType[] = [];

          country.forEach((value) => {
            const market_country = market_countries.find(
              ({ country_id }) => country_id === value
            );
            market_country && delete market_country.__typename;
            return res.push(
              market_country ||
                ({ country_id: value, market_id: id } as CountryMarketType)
            );
          });

          market_countries.forEach(
            ({ country_id, id }) =>
              !country.includes(country_id) && deleted.push(id as string)
          );

          await updateMarket({
            variables: {
              ...values,
              market_countries: res,
              deleted
            }
          });
        }
      })}
      initialValues={{
        ...(id ? market : initialValues),
        id: id || uuid.v4()
      }}
    >
      {({ isSubmitting }: FormikState<Values>): ReactNode => (
        <Form>
          <Field
            required
            label="Name"
            name="name"
            component={Input}
            placeholder="Enter Market Name"
          />
          <Field
            required
            multiple
            label="Country"
            name="country"
            component={SelectCountries}
            placeholder="Select location"
          />
          <Field
            required
            label="Color"
            name="colour"
            component={ColorPicker}
            placeholder="Choose Color"
          />
          <Field name="active" component={Switch} label="Active" />
          <Button
            block
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(MarketForm);
