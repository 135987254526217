import React, { FC } from 'react';

import { Col, Row, Switch as SwitchDefault, Typography } from 'antd';

import { withFieldMeta } from '@while-and-for/forms';

import { InputProps } from './interface';

const { Text } = Typography;

type Props = {
  disabled?: boolean;
} & InputProps<boolean>;

const Switch: FC<Props> = ({
  label,
  disabled = false,
  field,
  setFieldValue
}) => (
  <Row justify="space-between">
    <Col>
      <Text>{label}</Text>
    </Col>
    <Col>
      <SwitchDefault
        checked={field.value}
        disabled={disabled}
        onChange={(): void => {
          setFieldValue(!field.value);
        }}
      />
    </Col>
  </Row>
);

export default withFieldMeta<Props>(Switch);
