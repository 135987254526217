import React from 'react';
import styled from 'styled-components';

import url from 'assets/logo_rouge.png';

const Container = styled.div`
  width: 200px;
  height: auto;
  padding: 10px;

  position: absolute;
  top: 10px;
  right: 10px;

  z-index: 10;
  background: rgba(235, 235, 235, 0.6);

  &.public {
    visibility: visible;
  }
  &.private {
    visibility: hidden;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

type Props = {
  className?: string;
};

const Logo: React.FC<Props> = ({ className }) => (
  <Container className={className}>
    <Image src={url} alt="logo rouge" />
  </Container>
);

export default Logo;
