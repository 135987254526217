import React from 'react';

import Draggable from 'react-draggable';
import { Skeleton } from 'antd';

import { Holder, Card, Data } from './styles';

type Props = {
  bottom?: string;
  extra?: React.ReactNode;
  left?: string;
  loading?: boolean;
  right?: string;
  title: string;
  top?: string;
  visible?: boolean;
  maxWidth?: string;
  padding?: boolean;
  width?: string;
};

const CardData: React.FC<Props> = ({
  bottom,
  children,
  extra,
  left,
  loading,
  maxWidth,
  padding = true,
  right,
  top,
  title,
  visible,
  width
}) => (
  <Draggable bounds="parent">
    <Holder
      className={visible ? 'visible' : undefined}
      visible={!!visible}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      <Card extra={extra} title={title} padding={padding}>
        <Skeleton loading={!!loading} avatar active>
          <Data maxWidth={maxWidth} width={width}>
            {children}
          </Data>
        </Skeleton>
      </Card>
    </Holder>
  </Draggable>
);

export default CardData;
