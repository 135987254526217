import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import { DELETE_BRAND_BY_ID, GET_BRANDS } from 'graphql/queries';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Text } from 'components';
import { Divider, Popconfirm, message } from 'antd';

type Props = {
  id: string;
  active: boolean;
} & RouteComponentProps;

const Actions: React.FC<Props> = ({ id, match }) => {
  const [deleteBrand] = useMutation(DELETE_BRAND_BY_ID, {
    refetchQueries: [{ query: GET_BRANDS }]
  });

  return (
    <>
      <Link to={`${match.url}?cb=true&id=${id}`}>
        <Text>Edit</Text>
      </Link>
      <Divider type="vertical" />
      <Popconfirm
        title="Are you sure?"
        onConfirm={async (): Promise<void> => {
          try {
            await deleteBrand({ variables: { id } });
            message.success('Brand removed successfully');
          } catch (err) {
            message.error(err.message);
          }
        }}
      >
        <Text type="danger" style={{ cursor: 'pointer' }}>
          Delete
        </Text>
      </Popconfirm>
    </>
  );
};

export default withRouter(Actions);
