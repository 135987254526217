import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import _get from 'lodash.get';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { Col, Row } from 'antd';
import { CardData, CorsImg } from 'components';

import { createBrandsFilter } from 'utilities/filters';

import { GET_BRANDS_BY_IDS } from './requests';

type Brand = {
  id: string;
  logo: string;
  name: string;
};

type Search = {
  b?: string;
  c?: string;
  m?: string;
};

type Props = {
  config: {
    brands: string[];
    countries: string[];
    date_range: string[];
    markets: string[];
  };
  visible: boolean;
} & RouteComponentProps;

const Brands: React.FC<Props> = ({ config, history, location, visible }) => {
  const { b, c, m, ...search } = queryString.parse(location.search) as Search;
  const filter = createBrandsFilter({
    brands: config.brands
  });
  const { data, loading } = useQuery(GET_BRANDS_BY_IDS, {
    variables: {
      config: filter
    },
    skip: !filter
  });

  const result = _get(data, 'brand');
  const { brands } = config;

  if (!result || result.length < 1 || !brands || brands.length < 1) return null;
  const toggleBrand = (id: string): void => {
    const active = id === b;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        ...search,
        ...(c && { c }),
        ...(m && { m }),
        ...{ b: active ? undefined : id }
      })
    });
  };

  return (
    <CardData
      loading={loading}
      visible={visible}
      title="Brands"
      left="680px"
      bottom="20px"
      padding={false}
      maxWidth="280px"
    >
      <Row>
        {result.map(({ id, logo, name }: Brand) => (
          <Col
            key={id}
            span={6}
            className="icon"
            onClick={(): void => toggleBrand(id)}
          >
            <CorsImg
              active={id === b}
              alt={name}
              src={`${logo}?${Date.now()}`}
              style={{ padding: '4px' }}
              crossOrigin="anonymous"
            />
          </Col>
        ))}
      </Row>
    </CardData>
  );
};

export default withRouter(Brands);
