import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Divider, message, Popconfirm } from 'antd';
import { Text } from 'components';

import { DELETE_METRICS } from 'graphql/mutations';
import { GET_METRICS } from 'graphql/queries';

type Props = {
  id: string;
} & RouteComponentProps;

const Actions: React.FC<Props> = ({ history, id, location }) => {
  const { search, pathname } = location;
  const query = queryString.parse(search);
  const [deleteMetrics] = useMutation(DELETE_METRICS);

  const handleConfirm = async (): Promise<void> => {
    try {
      await deleteMetrics({
        variables: { id },
        refetchQueries: [{ query: GET_METRICS, variables: { filter: {} } }]
      });
      message.success('Core Metric deleted!');
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <>
      <Button
        type="link"
        onClick={(): void => {
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              cm: true,
              id
            })
          });
        }}
      >
        Edit
      </Button>
      <Divider type="vertical" />
      <Popconfirm
        cancelText="No"
        onConfirm={handleConfirm}
        okText="Yes"
        placement="top"
        title="Delete metric?"
      >
        <Text type="danger" style={{ cursor: 'pointer' }}>
          Delete
        </Text>
      </Popconfirm>
    </>
  );
};

export default withRouter(Actions);
