import styled from 'styled-components';

export const ButtonGroup = styled.div`
  bottom: 10px;
  right: 10px;
  position: absolute;
  display: inline;

  .ant-btn {
    border: none;
    box-shadow: none;
  }
`;
