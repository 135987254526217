import gql from 'graphql-tag';

export const GET_USER_BY_ID = gql`
  query GetUserById($id: uuid!) {
    user(where: { id: { _eq: $id } }) {
      id
      email
      first_name
      last_name
      admin
      registration_complete
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents($filter: event_bool_exp) {
    event(order_by: { created_at: asc }, where: $filter) {
      id
      name
      country {
        name
      }
      link
      start_date
      end_date
      user {
        first_name
        last_name
      }
      event_brands {
        brand {
          id
        }
      }
    }
  }
`;

export const GET_EVENT = gql`
  query GetEvent($id: uuid!) {
    event_by_pk(id: $id) {
      id
      name
      country_id
      country {
        name
      }
      link
      start_date
      end_date
      user {
        first_name
        last_name
      }
      event_brands {
        id
        brand_id
        event_id
      }
    }
  }
`;

export const GET_MARKETS = gql`
  query GetMarkets {
    market(order_by: { name: asc }, where: { active: { _eq: true } }) {
      id
      name
      active
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    country(order_by: { name: asc }, where: { active: { _eq: true } }) {
      id
      name
      active
      population
      lat
      long
    }
  }
`;

export const ADMIN_GET_MARKETS = gql`
  query GetMarkets {
    market(order_by: { name: asc }) {
      id
      name
      colour
      active
      market_countries_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const ADMIN_GET_BRANDS = gql`
  query GetBrands {
    brand(order_by: { name: asc }) {
      id
      name
      logo
      brand_countries_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const ADMIN_GET_COUNTRIES = gql`
  query GetCountries {
    country(order_by: { active: desc, name: asc }) {
      id
      name
      shortcode
      population
      active
    }
  }
`;

export const ADMIN_GET_USERS = gql`
  query GetUsers {
    user(order_by: { first_name: asc }) {
      id
      email
      first_name
      last_name
      admin
      registration_complete
      created_at
    }
  }
`;

export const GET_BRANDS = gql`
  query GetBrands {
    brand(order_by: { name: asc }) {
      id
      name
      logo
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    users(order_by: { email: asc }) {
      id
      email
      first_name
      last_name
      admin
      registration_complete
    }
  }
`;

export const GET_METRICS = gql`
  query GetMetric($filter: metrics_bool_exp) {
    metrics(order_by: { date: desc, updated_at: desc }, where: $filter) {
      id
      date
      created_at
      country_id
      cns_budget
      cns_le
      gross_margin
      gross_margin_ratio
      a_and_p
      a_and_p_cns
      net_contribution
      cop
      percent_cognac
      sales_volume_budget
      sales_volume_le
      head_count
      brand {
        name
      }
      country {
        name
      }
      user {
        first_name
        last_name
      }
    }
  }
`;

export const GET_MAPS = gql`
  query GetMaps($user_id: uuid!) {
    map(where: { user_id: { _eq: $user_id } }, order_by: { updated_at: desc }) {
      id
      title
      config
      lat
      long
      created_at
      updated_at
    }
  }
`;

export const GET_MAP_BY_ID = gql`
  query GetMapById($id: uuid!) {
    map_by_pk(id: $id) {
      id
      title
      config
      display_config
      lat
      long
      zoom
      updated_at
    }
  }
`;

export const DELETE_BRAND_BY_ID = gql`
  mutation DeletBrandById($id: uuid!) {
    delete_brand(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_MARKET_BY_ID = gql`
  mutation DeleteMarketById($id: uuid!) {
    delete_market(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
