import React, { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { message } from 'antd';

const RestoreSession: React.FC<RouteComponentProps> = ({
  history,
  location
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { pathname } = location;
  useEffect(() => {
    if (loading || loaded || pathname !== '/') return;
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const {
          attributes: { sub }
        } = await Auth.currentAuthenticatedUser();
        sub && history.replace('/app');
        message.success('Session restored');
      } catch (err) {
        err && history.replace('');
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    })();
  }, [history, loaded, loading, pathname]);

  return null;
};

export default withRouter(RestoreSession);
