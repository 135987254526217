import * as Yup from 'yup';

export default Yup.object().shape({
  country_id: Yup.string().required('country is required'),
  brand_id: Yup.string().required('brand is required'),
  cns_budget: Yup.number().nullable(),
  cns_le: Yup.number().nullable(),
  gross_margin: Yup.number()
    .min(0, 'must be at least {min}')
    .max(100, 'must be less than {max}')
    .nullable(),
  gross_margin_ratio: Yup.number()
    .min(0, 'must be at least {min}')
    .max(100, 'must be less than {max}')
    .nullable(),
  a_and_p: Yup.number().nullable(),
  a_and_p_cns: Yup.number()
    .min(0, 'must be at least {min}')
    .max(100, 'must be less than {max}')
    .nullable(),
  net_contribution: Yup.number().nullable(),
  cop: Yup.number().nullable(),
  percent_cognac: Yup.number()
    .min(0, 'must be at least {min}')
    .max(100, 'must be less than {max}')
    .nullable(),
  sales_volumne_budget: Yup.number().nullable(),
  sales_volume_le: Yup.number().required('volume is required'),
  head_count: Yup.number().nullable(),
  date: Yup.string().required('date is required')
});
