import { RouteComponentProps } from 'react-router-dom';
import uuid from 'uuid';

export type CountryMarketType = {
  id?: string;
  country_id: string;
  market_id: string;
  __typename?: string;
};

export type Values = {
  country: string[];
  name?: string;
  colour?: string;
  active?: any;
  id?: string;
  created_by_user_id?: string;
  market_countries: CountryMarketType[];
};

export const initialValues = {
  country: [],
  name: undefined,
  colour: undefined,
  active: undefined,
  id: uuid.v4(),
  created_by_user_id: undefined,
  market_countries: []
} as Values;

export type Props = RouteComponentProps & {
  id?: string;
};
