import styled from 'styled-components';
import { Col as ColDefault, Row as RowDefault } from 'antd';

export const Holder = styled.div`
  position: absolute;
  width: 220px;
  height: auto;
  right: 20px;
  top: 20px;
  z-index: 100;
  background: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  padding: 4px;
`;

type ColProps = {
  last?: boolean;
};

export const Col = styled(ColDefault)<ColProps>`
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  border-right: ${({ last }): string | false | undefined =>
    !last && '1px solid #e0e0e0'};

  .ant-typography-copy {
    margin: 0;
  }

  .ant-switch {
    width: 44px;
    margin: auto;
  }
`;

export const Row = styled(RowDefault)`
  height: 100%;
`;
