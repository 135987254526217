import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SaveOutlined,
  ShareAltOutlined
} from '@ant-design/icons';

import { Button, message, Switch, Tooltip, Typography } from 'antd';
import { ButtonSearch } from 'components';
import html2canvas from 'html2canvas';

import { Col, Holder, Row } from './style';

const { Text } = Typography;

type Params = {
  id: string;
};

async function captureImage(): Promise<void> {
  try {
    const canvas = document.getElementById('export') as HTMLElement;
    canvas.classList.add('active');
    const result = await html2canvas(canvas, {
      allowTaint: false,
      useCORS: true
    });
    const target = document.getElementById('target_canvas') as HTMLElement;
    target.append(result);
    canvas.classList.remove('active');
  } catch (err) {
    message.error(err.message);
  }
}

type Props = RouteComponentProps<Params> & {
  setVisible: () => void;
};

const Actions: React.FC<Props> = ({
  history,
  match: { params },
  setVisible
}) => {
  const url = `${window.location.origin}/public/${params.id}`;
  return (
    <Holder>
      <Row gutter={4}>
        <Col span={6}>
          <Tooltip title="Hide map data">
            <Switch
              checkedChildren={<EyeOutlined />}
              unCheckedChildren={<EyeInvisibleOutlined />}
              defaultChecked
              onChange={setVisible}
            />
          </Tooltip>
        </Col>
        <Col span={6}>
          <Tooltip title="Download map">
            <ButtonSearch
              type="link"
              style={{ padding: '0px' }}
              disabled={!params.id}
              to="?export=true"
              onClick={(): void => {
                setTimeout((): Promise<void> => captureImage(), 500);
              }}
            >
              <DownloadOutlined style={{ fontSize: '16px' }} />
            </ButtonSearch>
          </Tooltip>
        </Col>
        <Col span={6}>
          <Button type="link" style={{ padding: 0 }}>
            <Text
              type="danger"
              copyable={{
                icon: (
                  <ShareAltOutlined
                    style={{ fontSize: '16px', marginLeft: 0 }}
                  />
                ),
                tooltips: ['Copy map url', 'Copied'],
                text: url
              }}
              disabled={!params.id}
            />
          </Button>
        </Col>
        <Col last span={6}>
          <Tooltip title="Save map" placement="bottomLeft">
            <Button
              disabled={!params.id}
              type="link"
              style={{ padding: '0' }}
              onClick={(): void => history.push('?mt=true')}
            >
              <SaveOutlined style={{ fontSize: '16px' }} />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Holder>
  );
};
export default withRouter(Actions);
