import styled from 'styled-components';

export default styled.div`
  width: 100%;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;
