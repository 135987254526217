import styled from 'styled-components';
import { Card as CardDefault } from 'antd';

type Props = {
  left?: number | string;
  right?: number | string;
  top?: number | string;
  bottom?: number | string;
  visible: boolean;
};

type Position = string | number | undefined;

export const Holder = styled.div`
  visibility: ${({ visible }: Props): string =>
    visible ? 'visible' : 'hidden'};
  opacity: ${({ visible }: Props): number => (visible ? 1 : 0)};
  transition: visibility 0s linear 0.5s, opacity 0.3s;
  cursor: move;
  position: absolute;
  left: ${({ left }: Props): Position => left};
  right: ${({ right }: Props): Position => right};
  top: ${({ top }: Props): Position => top};
  bottom: ${({ bottom }: Props): Position => bottom};
  z-index: 1000;

  &.visible {
    transition: visibility 0s, opacity 0.3s;
  }
`;

type DataProps = {
  maxWidth?: string;
  width?: string;
};

export const Data = styled.div<DataProps>`
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
`;

type CardProps = {
  padding: boolean;
};

export const Card = styled(CardDefault)<CardProps>`
  .ant-card-body {
    padding: ${({ padding }) => (padding ? '8px 12px' : '1px 0 0 0')};
  }
  .ant-card-head-title {
    font-weight: 600;
  }
`;
