import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _get from 'lodash.get';

import { Col, Row, Tag } from 'antd';
import { CardData } from 'components';
import { Typography } from 'antd';

import { createActivitiesFilter } from 'utilities/filters';

import { GET_BRAND_EVENTS_BY_CONFIG } from './requests';

const { Paragraph } = Typography;

type Event = {
  event: EventItem;
};

type EventItem = {
  id: string;
  name: string;
  link?: string;
};

type Brand = {
  id: string;
  logo: string;
  name?: string;
  brand_events: Event[];
};

type Search = {
  c?: string;
  m?: string;
};

type Props = {
  config: {
    brands: string[];
    countries: string[];
    date_range: [string, string];
    markets: string[];
  };
  visible: boolean;
} & RouteComponentProps;

const Activities: React.FC<Props> = ({ config, location, visible }) => {
  const { c, m } = queryString.parse(location.search) as Search;
  const filter = createActivitiesFilter({
    ...config,
    ...(c && {
      countries: [c],
      markets: []
    }),
    ...(m && {
      countries: [],
      markets: [m]
    })
  });

  const { data, loading } = useQuery(GET_BRAND_EVENTS_BY_CONFIG, {
    variables: { config: filter },
    skip: !filter
  });

  const { brands } = config;
  const result = _get(data, 'brand') as Brand[];
  if (!brands || brands.length < 1 || !result || result.length < 1) return null;

  return (
    <CardData
      loading={loading}
      visible={visible}
      title="Activities"
      left="240px"
      bottom="20px"
      maxWidth="400px"
    >
      {result.map(
        ({ name, brand_events }): ReactNode => (
          <Row key={name}>
            <Paragraph>{name}</Paragraph>
            <Col span={24}>
              {brand_events.length > 0 &&
                brand_events.map(
                  ({ event }: Event): ReactNode => {
                    const EventTag = (
                      <Tag
                        style={{ margin: '2px' }}
                        color={`#${event.link ? 'e13737' : '666'}`}
                      >
                        {event.name}
                      </Tag>
                    );

                    if (!event.link) return EventTag;

                    return (
                      <a
                        key={event.id}
                        href={event.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {EventTag}
                      </a>
                    );
                  }
                )}
            </Col>
          </Row>
        )
      )}
    </CardData>
  );
};

export default withRouter(Activities);
