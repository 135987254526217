import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';

import gql from 'graphql-tag';

export const GET_METRIC_BY_ID = gql`
  query($id: uuid!) {
    metrics(where: { id: { _eq: $id } }) {
      id
      brand_id
      country_id
      cns_budget
      cns_le
      gross_margin
      gross_margin_ratio
      a_and_p
      a_and_p_cns
      net_contribution
      cop
      percent_cognac
      sales_volume_budget
      sales_volume_le
      head_count
      date
    }
  }
`;

export const ADD_METRICS = gql`
  mutation UpsertMetrics(
    $id: uuid
    $a_and_p: numeric
    $a_and_p_cns: numeric
    $brand_id: uuid!
    $cns_budget: numeric
    $cns_le: numeric
    $cop: numeric
    $country_id: uuid!
    $date: timestamptz!
    $gross_margin: numeric
    $gross_margin_ratio: numeric
    $head_count: numeric
    $net_contribution: numeric
    $percent_cognac: numeric
    $sales_volume_budget: numeric
    $sales_volume_le: numeric
  ) {
    insert_metrics(
      objects: {
        id: $id
        a_and_p: $a_and_p
        a_and_p_cns: $a_and_p_cns
        brand_id: $brand_id
        cns_budget: $cns_budget
        cns_le: $cns_le
        cop: $cop
        country_id: $country_id
        date: $date
        gross_margin: $gross_margin
        gross_margin_ratio: $gross_margin_ratio
        head_count: $head_count
        net_contribution: $net_contribution
        percent_cognac: $percent_cognac
        sales_volume_budget: $sales_volume_budget
        sales_volume_le: $sales_volume_le
      }
      on_conflict: {
        constraint: metrics_pkey
        update_columns: [
          a_and_p
          a_and_p_cns
          brand_id
          cns_budget
          cns_le
          cop
          country_id
          date
          gross_margin
          gross_margin_ratio
          head_count
          net_contribution
          percent_cognac
          sales_volume_budget
          sales_volume_le
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const onError = (e: Error): void => {
  message.error(e.message);
};

export const onSuccess = (
  history: RouteComponentProps['history']
) => (): void => {
  message.success('Core Metrics saved');
  history.goBack();
};
