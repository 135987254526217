import { RouteComponentProps } from 'react-router-dom';
import uuid from 'uuid';

export type Values = {
  a_and_p: number | null;
  a_and_p_cns: number | null;
  brand_id?: string;
  cns_budget: number | null;
  cns_le: number | null;
  cop: number | null;
  country_id?: string;
  date?: string;
  gross_margin: number | null;
  gross_margin_ratio: number | null;
  head_count: number | null;
  net_contribution: number | null;
  percent_cognac: number | null;
  sales_volume_le: number | null;
  id?: string;
};

export const initialValues = {
  a_and_p: null,
  a_and_p_cns: null,
  brand_id: undefined,
  cns_budget: null,
  cns_le: null,
  cop: null,
  country_id: undefined,
  date: undefined,
  gross_margin: null,
  gross_margin_ratio: null,
  head_count: null,
  net_contribution: null,
  percent_cognac: null,
  sales_volume_le: null,
  id: uuid.v4()
} as Values;

export type Props = RouteComponentProps & {
  id?: string;
};
