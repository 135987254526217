import React, { ReactNode } from 'react';
import moment from 'moment';

import { date, toCurrency } from '@while-and-for/forms';

import Actions from '../Actions';

function RenderValue(a?: string): ReactNode {
  return toCurrency('')(a) || '–';
}

type User = {
  first_name?: string;
  last_name?: string;
  date: string;
};

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    render: date,
    sorter: (a: User, b: User): number => {
      const diff = moment(b.date).diff(moment(a.date));
      return diff !== 0 ? (diff > 0 ? 1 : -1) : 0;
    }
  },
  {
    title: 'Country',
    dataIndex: ['country', 'name'],
    render: RenderValue
  },
  {
    title: 'Brand',
    dataIndex: ['brand', 'name'],
    render: RenderValue
  },
  {
    title: 'Sales Volume (Budget)',
    dataIndex: 'sales_volume_budget',
    align: 'right',
    render: RenderValue
  },
  {
    title: 'Sales Volume (LE)',
    dataIndex: 'sales_volume_le',
    align: 'right',
    render: RenderValue
  },
  {
    title: 'Owner',
    dataIndex: 'user',
    render: function RenderUser({ first_name, last_name }: User): string {
      return `${first_name || ''}${last_name ? ' ' + last_name : ''}`;
    }
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: function RenderActions(id: string): ReactNode {
      return <Actions id={id} />;
    }
  }
];

export default columns;
