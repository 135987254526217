import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Paragraph, Modal } from 'components';

import { ResetPassword } from '@while-and-for/forms';

import { useMutation } from '@apollo/react-hooks';
import { REGISTER_USER } from 'graphql/mutations';
import Auth from '@aws-amplify/auth';
import { message } from 'antd';

import { submit, onError } from './requests';

type Props = {
  user?: any;
} & RouteComponentProps;

const ChangePasswordModal: React.FC<Props> = ({ history, user }) => {
  const [updateUser] = useMutation(REGISTER_USER);
  return (
    <Modal title="Password update required" name="force">
      <>
        <Paragraph>
          {'As this is your first time signing in,'}
          {' you will need to change your password to continue.'}
        </Paragraph>
        <ResetPassword
          submit={submit(user)}
          onSuccess={async (): Promise<void> => {
            const user = await Auth.currentAuthenticatedUser();
            updateUser({ variables: { id: user?.attributes?.sub } });
            history.push('/app');
            message.success('Password change successful');
          }}
          onError={onError}
        />
      </>
    </Modal>
  );
};

export default withRouter(ChangePasswordModal);
