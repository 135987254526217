import { Typography } from 'antd';

const { Text, Paragraph } = Typography;

export { default as H1 } from './H1';
export { default as H2 } from './H2';
export { default as H3 } from './H3';
export { default as H4 } from './H4';
export { default as Title } from './Title';
export { Text, Paragraph };
