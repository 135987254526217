import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Divider, message, Popconfirm } from 'antd';
import { Text } from 'components';

import { ACTIVE_MARKET } from 'graphql/mutations';
import { ADMIN_GET_MARKETS, DELETE_MARKET_BY_ID } from 'graphql/queries';

type Props = {
  id: string;
  active: boolean;
} & RouteComponentProps;

const Actions: React.FC<Props> = ({ id, match, active }) => {
  const [activeMarket] = useMutation(ACTIVE_MARKET);
  const [deleteMarket] = useMutation(DELETE_MARKET_BY_ID, {
    refetchQueries: [{ query: ADMIN_GET_MARKETS }]
  });

  const enableMetrics = async (): Promise<void> => {
    try {
      await activeMarket({
        variables: { id, active: !active },
        refetchQueries: [{ query: ADMIN_GET_MARKETS }]
      });
      message.success(`Country ${active ? 'Disabled' : 'Enabled'}`);
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <>
      <Link to={`${match.url}?cm=true&id=${id}`}>
        <Text>Edit</Text>
      </Link>
      <Divider type="vertical" />
      <Button type="link" onClick={enableMetrics}>
        <Text type="warning">{active ? 'Disable' : 'Enable'}</Text>
      </Button>
      <Divider type="vertical" />
      <Popconfirm
        title="Are you sure?"
        onConfirm={async (): Promise<void> => {
          try {
            await deleteMarket({ variables: { id } });
            message.success('Market removed successfully');
          } catch (err) {
            message.error(err.message);
          }
        }}
      >
        <Text type="danger" style={{ cursor: 'pointer' }}>
          Delete
        </Text>
      </Popconfirm>
    </>
  );
};

export default withRouter(Actions);
