import React, { ReactNode } from 'react';
import { Text } from 'components';

import { toCurrency } from '@while-and-for/forms';

const toPercent = (a?: number): string =>
  a ? `${Number(a.toFixed(1)) * 1} %` : '';

const toKEuro = (b?: number): string =>
  b
    ? `${b}`.slice(0, `${b}`.length - 3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      ' (K EUR)'
    : '-';

type DisplayKeys =
  | 'cop'
  | 'cns_le'
  | 'a_and_p'
  | 'cns_budget'
  | 'head_count'
  | 'population'
  | 'a_and_p_cns'
  | 'gross_margin'
  | 'percent_cognac'
  | 'sales_volume_le'
  | 'net_contribution'
  | 'gross_margin_ratio'
  | 'sales_volume_budget';

type DataMap = {
  key: DisplayKeys;
  type: string;
  format: (a?: any) => any;
};

export const dataMap: DataMap[] = [
  {
    key: 'population',
    type: 'Population',
    format: toCurrency('')
  },
  {
    key: 'sales_volume_budget',
    type: 'Sales Volume (Budget)',
    format: toCurrency('')
  },
  {
    key: 'sales_volume_le',
    type: 'Sales Volume (LE)',
    format: toCurrency('')
  },
  {
    key: 'head_count',
    type: 'Headcount',
    format: toCurrency('')
  },
  {
    key: 'cns_budget',
    type: 'CNS (Budget)',
    format: toKEuro
  },
  {
    key: 'cns_le',
    type: 'CNS (LE)',
    format: toKEuro
  },
  {
    key: 'gross_margin',
    type: 'Gross Margin',
    format: toPercent
  },
  {
    key: 'gross_margin_ratio',
    type: 'Gross Margin Ratio',
    format: toPercent
  },
  {
    key: 'a_and_p',
    type: 'A&P',
    format: toCurrency('')
  },
  {
    key: 'a_and_p_cns',
    type: 'A&P & CNS',
    format: toPercent
  },
  {
    key: 'net_contribution',
    type: 'Net Contribution',
    format: toCurrency('')
  },
  {
    key: 'cop',
    type: 'COP',
    format: toCurrency('')
  },
  {
    key: 'percent_cognac',
    type: '% Cognac',
    format: toPercent
  }
];

type Column = {
  title: string;
  dataIndex: string;
  key: string;
  align?: 'left' | 'center' | 'right';
  render?: (a: any) => ReactNode;
};

export const columns = [
  {
    dataIndex: 'type',
    key: 'type',
    align: 'left'
  },
  {
    dataIndex: 'result',
    key: 'result',
    align: 'right',
    render: function RenderResult(result: string): ReactNode {
      return <Text strong>{result}</Text>;
    }
  }
] as Column[];
