import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';

export const GET_MARKET_BY_ID = gql`
  query($id: uuid!) {
    market_by_pk(id: $id) {
      id
      name
      colour
      active
      market_countries(order_by: { country: { name: asc } }) {
        id
        country_id
        market_id
      }
    }
  }
`;

export const ADD_MARKET = gql`
  mutation Upsertmarket(
    $id: uuid
    $name: String
    $colour: String
    $active: Boolean
    $market_countries: [country_market_insert_input!]!
    $created_by_user_id: String
    $deleted: [uuid!]!
  ) {
    insert_market(
      objects: { id: $id, name: $name, colour: $colour, active: $active }
      on_conflict: {
        constraint: market_pkey
        update_columns: [active, colour, name]
      }
    ) {
      affected_rows
    }
    insert_country_market(
      objects: $market_countries
      on_conflict: { constraint: country_marker_pkey, update_columns: [] }
    ) {
      affected_rows
    }
    delete_country_market(where: { id: { _in: $deleted } }) {
      affected_rows
    }
  }
`;

export const onError = (e: Error): void => {
  message.error(e.message);
};

export const onSuccess = (
  history: RouteComponentProps['history']
) => (): void => {
  message.success('Market saved');
  history.goBack();
};
