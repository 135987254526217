import React, { ReactNode, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _get from 'lodash.get';

import { Field, Form, Formik, FormikHelpers, FormikState } from 'formik';
import { Button, List, message, Row } from 'antd';
import { Loading } from '@while-and-for/components';
import { handleSubmit } from '@while-and-for/forms';

import { onError } from 'forms/utilities/handleSubmit';
import { Switch } from 'forms/components';

import Item from './Item';

import { GET_MAP_BY_ID } from 'graphql/queries';
import { UPDATE_MAP_DISPLAY } from './requests';

export type Values = {
  id: string;
  display_config: {
    cop: boolean;
    cns_le: boolean;
    a_and_p: boolean;
    cns_budget: boolean;
    head_count: boolean;
    population: boolean;
    a_and_p_cns: boolean;
    gross_margin: boolean;
    percent_cognac: boolean;
    sales_volume_le: boolean;
    net_contribution: boolean;
    gross_margin_ratio: boolean;
    sales_volume_budget: boolean;
  };
};

type Props = {
  close: () => void;
  id: string;
};

const MetricsDisplayForm: React.FC<Props> = ({ close, id }) => {
  const { data, loading, error } = useQuery(GET_MAP_BY_ID, {
    variables: { id },
    skip: !id
  });
  const [updateMap] = useMutation(UPDATE_MAP_DISPLAY, {
    refetchQueries: [{ query: GET_MAP_BY_ID, variables: { id } }]
  });
  const display_config = _get(data, 'map_by_pk.display_config', {});

  useEffect(() => {
    if (!error) return;
    message.error(error.message);
  }, [error]);

  if (loading) return <Loading />;

  return (
    <Formik
      initialValues={{
        id,
        display_config
      }}
      onSubmit={(values: any, form: any) => {
        handleSubmit({
          submit: (): any =>
            updateMap({
              variables: values
            }),
          onSuccess: close,
          onError
        })(values, form);
      }}
    >
      {({
        isSubmitting
      }: FormikState<Values> & FormikHelpers<Values>): ReactNode => (
        <Form>
          <List>
            <Item>
              <Field
                size="small"
                name="display_config.population"
                component={Switch}
                label="Population"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.sales_volume_budget"
                component={Switch}
                label="Sales Volume (Budget)"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.sales_volume_le"
                component={Switch}
                label="Sales Volume (LE)"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.head_count"
                component={Switch}
                label="Headcount"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.cns_budget"
                component={Switch}
                label="CNS (Budget)"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.cns_le"
                component={Switch}
                label="CNS (LE)"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.gross_margin"
                component={Switch}
                label="Gross Margin"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.gross_margin_ratio"
                component={Switch}
                label="Gross Margin Ratio"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.a_and_p"
                component={Switch}
                label="A&P"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.a_and_p_cns"
                component={Switch}
                label="A&P & CNS"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.net_contribution"
                component={Switch}
                label="Net Contribution"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.cop"
                component={Switch}
                label="COP"
              />
            </Item>
            <Item>
              <Field
                size="small"
                name="display_config.percent_cognac"
                component={Switch}
                label="% Cognac"
              />
            </Item>
          </List>
          <Row
            justify="end"
            style={{ paddingBottom: '12px', paddingRight: '12px' }}
          >
            <Button
              style={{ marginRight: '8px' }}
              type="primary"
              htmlType="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Apply
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default MetricsDisplayForm;
