import moment from 'moment';

type DateFilter = {
  _or?: {
    [key: string]: {
      _gte?: moment.Moment;
      _lte?: moment.Moment;
    };
  }[];
};

export function getDateRange(
  range?: [string | undefined, string | undefined]
): DateFilter | void {
  const [start, end] = range || [];
  if (!start || !end) return;
  return {
    _or: [
      {
        start_date: {
          _gte: moment(start),
          _lte: moment(end)
        }
      },
      {
        end_date: {
          _gte: moment(start),
          _lte: moment(end)
        }
      }
    ]
  };
}

type BrandsParams = {
  brands?: string[];
  countries?: string[];
  markets?: string[];
};

type BrandsResult = {
  [key: string]: any;
};

type FilterProps = Record<string, unknown> | boolean;

export function createBrandsFilter({
  brands
}: BrandsParams): BrandsResult | void {
  const b = brands && brands.length > 0;

  if (!b) return;

  return {
    id: { _in: brands }
  };
}

type FilterParams = {
  brands?: string[];
  countries?: string[];
  markets?: string[];
  date_range?: [string | undefined, string | undefined];
};

export function createEventsFilter({
  brands,
  countries,
  date_range
}: FilterParams): FilterProps {
  const b = brands && brands.length > 0;
  const c = countries && countries.length > 0;
  const d = date_range && date_range.length === 2;

  if (!b && !c && !d) return false;

  return {
    ...(b && {
      event_brands: {
        brand: {
          id: { _in: brands }
        }
      }
    }),
    ...(c && {
      country_id: { _in: countries }
    }),
    ...(d && getDateRange(date_range))
  };
}

export function createActivitiesFilter({
  brands,
  countries,
  date_range,
  markets
}: FilterParams): FilterProps {
  const b = brands && brands.length > 0;
  const c = countries && countries.length > 0;
  const d = date_range && date_range.length === 2;
  const m = markets && markets.length > 0;
  if (!b && !c && !d && !m) return false;
  const or = [];
  c && or.push({ country_id: { _in: countries } });
  m &&
    or.push({
      country: {
        country_markets: { market_id: { _in: markets } }
      }
    });

  return {
    ...(b && {
      id: { _in: brands }
    }),
    ...(or.length > 0 && {
      brand_countries: {
        _or: or
      }
    }),
    ...(d && {
      brand_events: {
        event: getDateRange(date_range)
      }
    })
  };
}

export function createMetricsFilter({
  brands,
  countries,
  date_range,
  markets
}: FilterParams): FilterProps {
  const b = brands && brands.length > 0;
  const c = countries && countries.length > 0;
  const d = date_range && date_range.length === 2;
  const m = markets && markets.length > 0;
  if (!b && !c && !d && !m) return false;
  const [start, end] = date_range || [];

  const or = [];
  b && !c && !m && or.push({ brand_id: { _in: brands } });
  c &&
    or.push({
      country_id: { _in: countries },
      ...(b && { brand_id: { _in: brands } })
    });
  m &&
    or.push({
      country: {
        country_markets: { market_id: { _in: markets } }
      },
      ...(b && { brand_id: { _in: brands } })
    });

  return {
    ...(or.length > 0 && { _or: or }),
    ...(d && {
      date: {
        _gte: moment(start),
        _lte: moment(end)
      }
    })
  };
}

export function createPopulationFilter({
  countries,
  markets
}: FilterParams): FilterProps {
  const c = countries && countries.length > 0;
  const m = markets && markets.length > 0;
  if (!c && !m) return false;

  const or = [];
  c && or.push({ id: { _in: countries } });
  m &&
    or.push({
      country_markets: { market_id: { _in: markets } }
    });

  return {
    ...(or.length > 0 && { _or: or })
  };
}
