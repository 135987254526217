import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 2px;
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const FormContainer = styled.div`
  position: relative;
  padding: 20px;
  max-width: 340px;
`;
