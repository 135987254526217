import React, { ReactNode } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import _get from 'lodash.get';
import uuid from 'uuid';
import { Button } from 'antd';
import { Formik, FormikState, Field } from 'formik';
import { handleSubmit, Input, Select, Option } from '@while-and-for/forms';
import { Form } from 'forms/components';
import { Loading, ErrorComponent } from '@while-and-for/components';

import { ADMIN_GET_USERS } from 'graphql/queries';
import { GET_USER_BY_ID, ADD_USERS, onError, onSuccess } from './requests';

import FormSchema from './FormSchema';
import { Values, Props, initialValues } from './types';

const UserForm: React.FC<Props> = ({ history, id }) => {
  const { data, loading, error } = useQuery(GET_USER_BY_ID, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only'
  });
  const [updateUsers] = useMutation(ADD_USERS, {
    refetchQueries: [{ query: ADMIN_GET_USERS }]
  });

  if (loading) return <Loading />;
  if (error) return <ErrorComponent error={error} />;
  const { __typename, registration_complete, ...rest } = _get(
    data,
    'user[0]',
    {}
  );
  return (
    <Formik
      validationSchema={FormSchema}
      onSubmit={handleSubmit({
        onError,
        onSuccess: onSuccess(history),
        submit: (values: Values): void => {
          updateUsers({
            variables: values
          });
        }
      })}
      initialValues={{
        ...(id ? rest : initialValues),
        id: id || uuid.v4()
      }}
    >
      {({ isSubmitting }: FormikState<Values>): ReactNode => (
        <Form>
          <Field
            required
            disabled={!!id}
            label="Email"
            name="email"
            component={Input}
            placeholder="Enter Email"
          />
          <Field
            required
            label="First Name"
            name="first_name"
            component={Input}
            placeholder="Enter First Name"
          />
          <Field
            required
            label="Last Name"
            name="last_name"
            component={Input}
            placeholder="Enter Last Name"
          />
          <Field
            required
            label="Select User Role"
            name="admin"
            component={Select}
            placeholder="Select User Role"
          >
            <Option key="2" value={false as any}>
              User
            </Option>
            <Option key="1" value={true as any}>
              Admin
            </Option>
          </Field>
          <Button
            block
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(UserForm);
