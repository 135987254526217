import styled from 'styled-components';

type Props = {
  active: boolean;
};

export default styled.img<Props>`
  height: auto;
  width: 100%;
  background: ${({ active }): string =>
    active ? 'rgba(230, 45, 57, 0.45)' : '#ffffff'};
  border: ${({ active }): string => (active ? '1px solid #e62d39' : 'none')};
`;
