import React, { ReactNode } from 'react';

import Actions from '../Actions';
import Active from '../Active';

import { SearchOutlined } from '@ant-design/icons';

import { Input, Button } from 'antd';

type Country = {
  shortcode: string;
  active: boolean;
  name: string;
};

const columns = [
  {
    title: 'Country',
    dataIndex: 'name',
    render: function RenderCountry(
      name: string,
      { shortcode }: Country
    ): ReactNode {
      return (
        <>
          <img
            src={`https://restcountries.eu/data/${shortcode.toLowerCase()}.svg`}
            height="15px"
            alt={`${name} flag`}
          />{' '}
          {name}
        </>
      );
    },
    sorter: (a: Country, b: Country): number => a.name.length - b.name.length,
    filterDropdown: function Filter({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: Record<string, unknown> & {
      selectedKeys: string[];
      setSelectedKeys: (a?: string[]) => void;
      confirm: () => void;
      clearFilters: () => void;
    }): ReactNode {
      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Country`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
            onClick={(): void => confirm()}
          >
            Search
          </Button>
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={() => clearFilters()}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: function Icon(): ReactNode {
      return <SearchOutlined />;
    },
    onFilter: (
      value: string | number | boolean,
      record: Record<string, any>
    ): boolean => {
      if (typeof value !== 'string') return false;
      return record['name']
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    }
  },
  {
    title: 'Shortcode',
    dataIndex: 'shortcode'
  },
  {
    title: 'Population',
    dataIndex: 'population',
    align: 'right' as 'right' | 'left' | 'center' | undefined,
    render: function RenderPop(a: number): ReactNode {
      return (
        <strong>{a?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
      );
    }
  },
  {
    title: 'Active',
    dataIndex: 'active',
    render: function RenderActive(active: boolean): ReactNode {
      return <Active active={active} />;
    }
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: function RenderActions(id: string, { active }: Country): ReactNode {
      return <Actions id={id} active={active} />;
    }
  }
];

export default columns;
