import gql from 'graphql-tag';

export const GET_CORE_METRICS_BY_CONFIG = gql`
  query MetricsByConfig(
    $config: metrics_bool_exp
    $countryConfig: country_bool_exp
  ) {
    metrics_aggregate(where: $config) {
      aggregate {
        sum {
          a_and_p
          cns_budget
          cns_le
          cop
          sales_volume_budget
          sales_volume_le
          head_count
        }
        avg {
          gross_margin
          gross_margin_ratio
          a_and_p_cns
          net_contribution
          percent_cognac
        }
      }
    }
    country_aggregate(where: $countryConfig) {
      aggregate {
        sum {
          population
        }
      }
    }
  }
`;
