import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

import { Select as SelectDefault } from 'antd';
import { Item, withFieldMeta } from '@while-and-for/forms';

import { InputProps } from './interface';

const { Option } = SelectDefault;

type Data = {
  id: string;
  name: string;
};

type Props = {
  dataKey: string;
  query: DocumentNode;
  multiple: boolean;
} & InputProps<string[]>;

const GqlSelect: React.FC<Props> = ({
  dataKey,
  error,
  field,
  label,
  multiple,
  placeholder,
  query,
  required,
  setFieldValue,
  status,
  style,
  touched,
  validate
}) => {
  const [dataSource, setDataSource] = useState<Data[]>([]);
  const [search, setSearch] = useState<string | undefined>();
  const { data, loading } = useQuery(query);
  const { value } = field;

  useEffect(() => {
    if (loading || !data) return;
    if (!search) {
      setDataSource(data[dataKey]);
      return;
    }
    const s = search.toLowerCase();
    const next = (data[dataKey] as Data[]).filter(({ name }) =>
      name.toLowerCase().includes(s)
    );
    setDataSource(next);
  }, [data, dataKey, loading, search, setDataSource]);

  return (
    <Item
      hasFeedback={validate}
      help={validate && touched && error}
      label={label}
      required={required}
      validateStatus={validate ? status : ''}
      style={style}
    >
      <SelectDefault
        showSearch
        defaultValue={value}
        filterOption={false}
        loading={loading}
        mode={multiple ? 'multiple' : undefined}
        onChange={(a: string[]): void => {
          setFieldValue(a);
          setSearch(undefined);
        }}
        onSearch={setSearch}
        placeholder={placeholder}
        value={value}
      >
        {dataSource.map(({ id, name }) => (
          <Option key={id} value={id} title={name}>
            {name}
          </Option>
        ))}
      </SelectDefault>
    </Item>
  );
};

GqlSelect.defaultProps = {
  multiple: false,
  validate: true
};

export default withFieldMeta(GqlSelect);
